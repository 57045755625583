import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {CountryHomePage} from "./CountryHomePage";
import {getCountryFlagName} from "./utils";

export const PlayerHomePage = (props) => {

    const [selectedCountry, setSelectedCountry] = useState(undefined);

    return (
        <Box>
            <h2>
                Vous êtes {props.player}
            </h2>
            <div className={"top-player-info-bar"}>
                <div className={"top-player-info-bar-box"}>
                    <img src={"public/anniv/2024/money.png"}/>
                    {props.world.players[props.player].money}
                    <img src={"public/anniv/2024/money.png"}/>
                </div>
                <div className={"top-player-info-bar-box"}>
                    <img src={"public/anniv/2024/points.png"}/>
                    {props.world.players[props.player].points}
                    <img src={"public/anniv/2024/points.png"}/>
                </div>
            </div>
            {
                !selectedCountry && <div>
                    <div>
                        Vos pays:
                    </div>
                    <div>
                        {
                            props.world.players[props.player].countries.map(country => {
                                return <button className={"country-button"}
                                               onClick={() => setSelectedCountry(country)}
                                               key={country}>
                                    <img
                                        style={{height: '90%', width: 'auto', marginRight: '24px'}}
                                        src={"public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                                    {country}
                                    <img
                                        style={{height: '90%', width: 'auto', marginRight: '24px'}}
                                        src={"public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                                </button>
                            })
                        }
                    </div>
                </div>
            }
            {
                selectedCountry && <div>
                    <div className="top-line" style={{marginTop: '24px'}}>
                        <button className={"big-button"} onClick={() => setSelectedCountry(undefined)}>
                            Retour à la liste de vos pays
                        </button>
                        <h2>
                            <img
                                style={{height: '34px', width: 'auto', marginRight: '24px'}}
                                src={"public/anniv/2024/flags/" + getCountryFlagName(selectedCountry) + ".png"}/>
                            Pays : {selectedCountry}
                        </h2>
                    </div>
                    <CountryHomePage
                        world={props.world}
                        player={props.player}
                        emitOrderBuy={props.emitOrderBuy}
                        emitOrderCountry={props.emitOrderCountry}
                        country={selectedCountry}/>
                </div>
            }

        </Box>
    )
}
