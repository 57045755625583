import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
export const ConsumptionPanel = (props) => {

    function getListOfCountriesThatProduceRawMaterial(rawMaterial){
        return Object.keys(props.world.countries).filter(country => props.world.countries[country].rawMaterial1 === rawMaterial || props.world.countries[country].rawMaterial2 === rawMaterial);
    }

    return (
        <Box>
            <h2>
                Approvisionnement en {props.neededRawMaterial}
            </h2>
            <div>
                {
                    getListOfCountriesThatProduceRawMaterial(props.neededRawMaterial).map(country => {
                        return <div key={country} className={"consumption-country"}>
                            <h3>
                                {country}
                            </h3>
                            <div>
                                Prix actuel : {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].rawMaterial1Price : props.world.countries[country].rawMaterial2Price}
                                <br/>
                                Prix futur : {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].orderRawMaterial1Price : props.world.countries[country].orderRawMaterial2Price}
                                <br/>
                                Votre commande actuelle : {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryShareRawMaterial1[props.country] || 0 : props.world.countries[country].countryShareRawMaterial2[props.country] || 0}
                                <br/>
                                Vous avez commandé : {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryWantRawMaterial1[props.country] || 0 : props.world.countries[country].countryWantRawMaterial2[props.country] || 0}
                                <br/>
                                <div className="top-line">
                                    {
                                        [-5, -1, 1, 5].map(modification =>
                                            <button
                                                disabled={modification < 0 && (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryWantRawMaterial1[props.country] || 0 : props.world.countries[country].countryWantRawMaterial2[props.country] || 0) <= 0}
                                                onClick={() => {
                                                    const newValue = props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryWantRawMaterial1[props.country] || 0 : props.world.countries[country].countryWantRawMaterial2[props.country] || 0;

                                                    props.emitOrderBuy({
                                                        countryBuyerName: props.country,
                                                        countrySellerName: country,
                                                        playerName: props.player,
                                                        rawMaterialName: props.neededRawMaterial,
                                                        quantity: Math.max(0, newValue + modification)
                                                    })
                                                }}
                                                key={modification}
                                                className={"small-button"}>
                                                {modification > 0 ? '+' : ''}{modification}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </Box>
    )
}
