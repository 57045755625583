import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {RawMaterialBlock} from "./RawMaterialBlock";
import {ConsumptionPanel} from "./ConsumptionPanel";
import {getCountryFlagName} from "./utils";

export const CountryHomePage = (props) => {


    const [panel, setPanel] = useState("Production");

    const [selectedConsommationResource, setSelectedConsommationResource] = useState(props.world.countries[props.country].neededRawMaterial1);

    function emitOrderDistributionRawMaterial(modification) {
        const newOrderDistributionRawMaterial = Math.max(0, Math.min(100, props.world.countries[props.country].orderDistributionRawMaterial + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderDistributionRawMaterial: newOrderDistributionRawMaterial
        })
    }

    function emitOrderDistributionMoneyPoints(modification) {
        const newOrderDistributionMoneyPoints = Math.max(0, Math.min(100, props.world.countries[props.country].orderDistributionMoneyPoints + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderDistributionMoneyPoints: newOrderDistributionMoneyPoints
        })
    }

    function emitOrderMoneyForFactory(modification) {
        const newOrderMoneyForFactory = Math.max(0, Math.min(100, props.world.countries[props.country].orderMoneyForFactory + modification));
        const newOrder = {
            countryName: props.country,
            playerName: props.player,
            orderMoneyForFactory: newOrderMoneyForFactory
        };
        console.log(newOrder);
        props.emitOrderCountry(newOrder);
    }

    function emitOrderInvestment(modification) {
        const newOrderInvestment = Math.max(0, Math.min(100, props.world.countries[props.country].orderInvestment + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderInvestment: newOrderInvestment
        })
    }

    function emitOrderPoliticalFinances(party, modification) {
        const newOrderPoliticalFinances = {};
         newOrderPoliticalFinances[party] = Math.max(0, Math.min(100, (props.world.countries[props.country].orderPoliticalFinances[party] || 0) + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderPoliticalFinances: newOrderPoliticalFinances
        })
    }

    return (
        <Box>
            <div className={"buttonPanelRow"}>
                <div className={panel === "Production" ? "buttonPanelSelected" : "buttonPanel"}
                     onClick={() => setPanel("Production")}>
                    Production
                </div>
                <div className={panel === "Consommation" ? "buttonPanelSelected" : "buttonPanel"}
                     onClick={() => setPanel("Consommation")}>
                    Consommation
                </div>
                <div className={panel === "Investissement" ? "buttonPanelSelected" : "buttonPanel"}
                     onClick={() => setPanel("Investissement")}>
                    Investissement
                </div>
            </div>
            {
                panel === "Production" ?
                    <div>
                        <h2>
                            Vous produisez
                        </h2>
                        <div className={"production-bar"}>
                            <RawMaterialBlock
                                emitOrderCountry={(newPrice) => {
                                    props.emitOrderCountry({
                                        countryName: props.country,
                                        playerName: props.player,
                                        orderRawMaterial1Price: newPrice
                                    })
                                }}
                                rawMaterial={props.world.countries[props.country].rawMaterial1}
                                rawMaterialPrice={props.world.countries[props.country].rawMaterial1Price}
                                orderRawMaterialPrice={props.world.countries[props.country].orderRawMaterial1Price}
                            >

                            </RawMaterialBlock>
                            <RawMaterialBlock
                                emitOrderCountry={(newPrice) => {
                                    props.emitOrderCountry({
                                        countryName: props.country,
                                        playerName: props.player,
                                        orderRawMaterial2Price: newPrice
                                    })
                                }}
                                rawMaterial={props.world.countries[props.country].rawMaterial2}
                                rawMaterialPrice={props.world.countries[props.country].rawMaterial2Price}
                                orderRawMaterialPrice={props.world.countries[props.country].orderRawMaterial2Price}
                            >

                            </RawMaterialBlock>
                        </div>
                        <div>
                            Ratio actuel entre resources:
                            <br/>
                            <div className={"distribution-bar"}>
                                <div>
                                    <img style={{height: '48px', width: 'auto', marginRight: '24px'}}
                                         src={"public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial1 + ".png"}/>
                                    {props.world.countries[props.country].rawMaterial1} - {props.world.countries[props.country].distributionRawMaterial}%
                                </div>
                                <div>
                                    {100 - props.world.countries[props.country].distributionRawMaterial}%
                                    - {props.world.countries[props.country].rawMaterial2}
                                    <img style={{height: '48px', width: 'auto', marginLeft: '24px'}}
                                         src={"public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial2 + ".png"}/>
                                </div>
                                <div style={{
                                    position: "absolute",
                                    height: '48px',
                                    width: '3px',
                                    backgroundColor: 'white',
                                    left: (25 + props.world.countries[props.country].distributionRawMaterial / 2) + "%"
                                }}>

                                </div>
                            </div>
                            <br/>
                            <div className={"top-line"}>
                                <div>
                                    Ratio souhaité : {props.world.countries[props.country].orderDistributionRawMaterial}%
                                </div>
                                {
                                    [-5, -1, 1, 5].map(modification =>
                                        <button
                                            disabled={(modification < 0 && props.world.countries[props.country].orderDistributionRawMaterial <= 0) || (modification > 0 && props.world.countries[props.country].orderDistributionRawMaterial >= 100)}
                                            onClick={() => emitOrderDistributionRawMaterial(modification)}
                                            key={modification}
                                            style={{marginLeft: '24px'}}
                                            className={"small-button"}>
                                            {modification > 0 ? '+' : ''}{modification}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div> :
                    undefined
            }
            {
                panel === "Consommation" ?
                    <div>
                        <h2>
                            Votre consommation
                        </h2>
                        <div>
                            Ratio consommation:
                            <br/>
                            <div className={"distribution-bar"}>
                                <div>
                                    <img style={{height: '48px', width: 'auto', marginRight: '24px'}}
                                         src={"public/anniv/2024/money.png"}/>
                                    Argent - {100 - props.world.countries[props.country].distributionMoneyPoints}%
                                </div>
                                <div>
                                    {props.world.countries[props.country].distributionMoneyPoints}% - Points
                                    <img style={{height: '48px', width: 'auto', marginLeft: '24px'}}
                                         src={"public/anniv/2024/points.png"}/>
                                </div>
                                <div style={{
                                    position: "absolute",
                                    height: '48px',
                                    width: '3px',
                                    backgroundColor: 'white',
                                    left: (25 + props.world.countries[props.country].distributionMoneyPoints / 2) + "%"
                                }}>

                                </div>
                            </div>
                            <br/>
                            <div className={"top-line"}>
                                <div>
                                    Ratio souhaité : {props.world.countries[props.country].orderDistributionMoneyPoints}%
                                </div>
                                {
                                    [-5, -1, 1, 5].map(modification =>
                                        <button
                                            disabled={(modification < 0 && props.world.countries[props.country].orderDistributionMoneyPoints <= 0) || (modification > 0 && props.world.countries[props.country].orderDistributionMoneyPoints >= 100)}
                                            onClick={() => emitOrderDistributionMoneyPoints(modification)}
                                            key={modification}
                                            style={{marginLeft: '12px'}}
                                            className={"small-button"}>
                                            {modification}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                        <h2>
                            Les resources dont vous avez besoin
                        </h2>
                        <div className={"production-bar"}>
                            <div className={"consumption-item"}
                                 onClick={() => setSelectedConsommationResource(props.world.countries[props.country].neededRawMaterial1)}
                                 style={{
                                background: selectedConsommationResource === props.world.countries[props.country].neededRawMaterial1 ?
                                    'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'transparent'
                            }}>
                                <img style={{height: '40px', width: 'auto'}}
                                     src={"public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial1 + ".png"}/>
                                {props.world.countries[props.country].neededRawMaterial1}
                                <img style={{height: '40px', width: 'auto'}}
                                     src={"public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial1 + ".png"}/>
                            </div>
                            <div className={"consumption-item"}
                                 onClick={() => setSelectedConsommationResource(props.world.countries[props.country].neededRawMaterial2)}
                                 style={{
                                background: selectedConsommationResource === props.world.countries[props.country].neededRawMaterial2 ?
                                    'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'transparent'
                            }}>
                                <img style={{height: '40px', width: 'auto'}}
                                     src={"public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial2 + ".png"}/>
                                {props.world.countries[props.country].neededRawMaterial2}
                                <img style={{height: '40px', width: 'auto'}}
                                     src={"public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial2 + ".png"}/>
                            </div>
                        </div>
                        {
                            selectedConsommationResource ?
                                <ConsumptionPanel emitOrderBuy={props.emitOrderBuy} world={props.world}
                                                  country={props.country}
                                                  neededRawMaterial={selectedConsommationResource}/>
                                : <div style={{width: '12px', height: '40vh'}}></div>
                        }
                    </div> :
                    undefined
            }
            {
                panel === "Investissement" ?
                    <div>
                        <h2>
                            Vous investissez
                        </h2>
                        <div>
                            <div className={"invest-box"}>
                                <h2>
                                    Dans votre pays ({props.country})
                                </h2>
                                <div>
                                    Nombre d'usines : {props.world.countries[props.country].factories}
                                    <br/>
                                    Argent accumulé pour les usines
                                    : {props.world.countries[props.country].totalMoneyForFactories}
                                    <br/>
                                    Investissement actuel : {props.world.countries[props.country].moneyForFactory}
                                    <br/>
                                    Ordre d'investissement : {props.world.countries[props.country].orderMoneyForFactory}
                                    <br/>
                                    <div className={"top-line"}>
                                        {
                                            [-5, -1, 1, 5].map(modification =>
                                                <button
                                                    disabled={(modification < 0 && props.world.countries[props.country].orderMoneyForFactory <= 0)}
                                                    onClick={() => emitOrderMoneyForFactory(modification)}
                                                    key={modification}
                                                    className={"small-button"}>
                                                    {modification}
                                                </button>
                                            )
                                        }
                                    </div>

                                    <br/>

                                </div>
                            </div>
                            <div className={"invest-box"}>
                                <h2>
                                    Dans votre contient ({props.world.countries[props.country].continentName})
                                </h2>
                                <div>
                                    Argent accumulé pour le continent
                                    : {props.world.continents[props.world.countries[props.country].continentName].totalInvestment}
                                    <br/>
                                    Investissement actuel : {props.world.countries[props.country].investment}
                                    <br/>
                                    Ordre d'investissement : {props.world.countries[props.country].orderInvestment}
                                    <br/>
                                    <div className={"top-line"}>
                                        {
                                            [-5, -1, 1, 5].map(modification =>
                                                <button
                                                    disabled={(modification < 0 && props.world.countries[props.country].orderInvestment <= 0)}
                                                    onClick={() => emitOrderInvestment(modification)}
                                                    key={modification}
                                                    className={"small-button"}>
                                                    {modification}
                                                </button>
                                            )
                                        }
                                    </div>

                                    <br/>

                                </div>
                            </div>
                            <div className={"invest-box"}>
                                <h2>
                                    Dans les partis politiques de l'{props.world.countries[props.country].continentName}
                                </h2>
                                <div>
                                    {
                                        Object.keys(props.world.countries[props.country].politicalFinances).map(party => {
                                            return <div key={party}>
                                                <h4>
                                                    {party}
                                                </h4>
                                                Argent accumulé par le parti : ???
                                                <br/>
                                                Investissement actuel
                                                : {props.world.countries[props.country].politicalFinances[party] || 0}
                                                <br/>
                                                Ordre d'investissement
                                                : {props.world.countries[props.country].orderPoliticalFinances[party] || 0}
                                                <br/>
                                                <div className={"top-line"}>
                                                    {
                                                        [-5, -1, 1, 5].map(modification =>
                                                            <button
                                                                disabled={(modification < 0 && props.world.countries[props.country].orderPoliticalFinances[party] <= 0)}
                                                                onClick={() => emitOrderPoliticalFinances(party, modification)}
                                                                key={modification}
                                                                className={"small-button"}>
                                                                {modification}
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div> :
                    undefined
            }
        </Box>
    )
}
