import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router";
import * as io from "socket.io-client";
import "./Anniv2024.css";
import constants from "../../../config/constants";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import {PlayerHomePage} from "./PlayerHomePage";


export const Anniv2024Page = () => {


    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [world, setWorld] = useState(undefined);
    const [player, setPlayer] = useState(undefined);
    const [socketAnniv, setSocketAnniv] = useState(undefined);

    useEffect(() => {
        console.log(constants.api.socketPath);
        const tempSocketAnniv = io.connect(constants.api.socketPath, {transports: ['websocket']});
        tempSocketAnniv.on('anniv2024-world', function (data) {
            console.log("jai recu un world");
            const dataWorld = JSON.parse(data);
            console.log(dataWorld);
            setWorld(dataWorld);
        });
        tempSocketAnniv.connect();
        tempSocketAnniv.emit("anniv2024-join", JSON.stringify({playerId: "player"}));
        setSocketAnniv(tempSocketAnniv);
        return () => {
            tempSocketAnniv.close();
            console.log("closing socket");
        }
    }, []);

    function emitOrderCountry(orderCountry) {
        if(socketAnniv){
            console.log("emitting order country at top level");
            socketAnniv.emit("anniv2024-order-country", JSON.stringify(orderCountry));
        }
    }

    function emitOrderBuy(orderBuy) {
        if(socketAnniv){
            console.log("emitting order buy at top level");
            socketAnniv.emit("anniv2024-order-buy", JSON.stringify(orderBuy));
        }
    }

    function displayPlayerChoice() {
        return <Box>
            <h2>
                Player Choice
            </h2>
            <div className="player-choice-button-containers">
                {
                    world && Object.keys(world.players).map(player => {
                        return <button
                            key={player}
                            className="big-button" onClick={() => {
                            setPlayer(player);
                        }}>
                            {player}
                        </button>
                    })
                }
            </div>
        </Box>
    }


    return (
        <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            <div style={{marginBottom: "3vh"}}>
                {
                    world ?
                        <div>
                            {
                                player ?
                                    <PlayerHomePage
                                        emitOrderBuy={emitOrderBuy}
                                        emitOrderCountry={emitOrderCountry}
                                        world={world}
                                        player={player}/>:
                                    displayPlayerChoice()
                            }

                        </div>:
                        <div>
                            Awaiting the world
                        </div>
                }
            </div>
        </Box>
    )
};


export default Anniv2024Page;
