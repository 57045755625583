import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export const RawMaterialBlock = (props) => {

    function emitOrderRawMaterialPrice(modification) {
        const newPrice = Math.max(0, props.orderRawMaterialPrice + modification);
        console.log("emitting order country");
        props.emitOrderCountry(newPrice);
    }

    return (
        <div className={"production-bar-box"}>
            <div className={"production-top-bar"}>
                <img src={"public/anniv/2024/resources/" + props.rawMaterial + ".png"}/>
                {props.rawMaterial}
                <img src={"public/anniv/2024/resources/" + props.rawMaterial + ".png"}/>
            </div>
            <div className={"production-bar"}>
                Prix actuel : {props.rawMaterialPrice}
                <br/>
                Prix souhaité : {props.orderRawMaterialPrice}
            </div>
            <div className={"production-order-bar"}>
                {
                    [-5, -1, 1, 5].map(modification =>
                        <button
                            disabled={modification < 0 && props.orderRawMaterialPrice <= 0}
                            onClick={() => emitOrderRawMaterialPrice(modification)}
                            key={modification}
                            className={"small-button"}>
                            {modification > 0 ? '+' : ''}{modification}
                        </button>
                    )
                }
            </div>
        </div>
    )

}
