import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./TopBar.css";

import {logout, signinGetUserInfosFromStorage} from "../../../store/authentication/actions";
import MenuIcon from '@mui/icons-material/Menu';
import {selectIsLoggedIn, selectSessionUser} from "../../../store/authentication/selectors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useHistory, useLocation} from "react-router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";


export const TopBar = () => {

    let history = useHistory();
    let location = useLocation();
    let dispatch = useDispatch();
    let mobile = window.innerWidth < 700;

    function handleNavigation(href) {
        history.push(href);
        handleCloseMenu();
    }

    const user = useSelector(selectSessionUser);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        dispatch(signinGetUserInfosFromStorage());
    }, []);

    // function componentDidMount() {
    //     const {signinGetUserInfosFromStorage} = this.props;
    //     signinGetUserInfosFromStorage();
    // }
    //
    // function componentDidUpdate() {
    //     if ((this.props.user.error || (this.props.error && this.props.error.message === "invalid_token")) && this.props.isLoggedIn) {
    //         const {logout} = this.props;
    //         logout();
    //         this.props.history.push("/login");
    //     }
    // }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    let navComponent;
    navComponent = [
        {name: "Killing Zeus", href: "killing-zeus"},
        {name: "RTS Ultra Myth", href: "ultra-myth"},
    ];
    if (isLoggedIn && user) {
        if (user.canPlay7wonders) {
            navComponent.push({
                name: "Board Games",
                href: "board-games"
            });
        }
        if(user.admin){
            navComponent.push({
                name: "Admin",
                href: "admin"
            });
        }
    }
    const resp = {display: {xs: 'none', md: 'flex'}};
    if (window.location.pathname.startsWith("/monitor") ||
        window.location.pathname.startsWith("/terraforming") ||
        window.location.pathname.startsWith("/throughTheAges") ||
        window.location.pathname.startsWith("/megawatt") ||
        window.location.pathname.startsWith("/sevenWonders")) {
        return undefined;
    }
    return <div>
        <div className={"top-bar"}>
            <div className={"top-bar-left"}>
                <Box sx={{fontSize: "1.5em", display: {xs: 'none', md: 'flex'}}}
                     onClick={() => handleNavigation("/")}
                     className={"top-bar-left-home"}>
                    <img src="/public/images/icons/large_icon.png" alt="logo"/>
                    <div className={"title"}>
                        Welcome Games
                    </div>
                </Box>
                <Box sx={{fontSize: "1em", display: {xs: 'flex', md: 'none'}}}
                     onClick={() => handleNavigation("/")}
                     className={"top-bar-left-home"}>
                    <div className={"title"}>
                        Welcome Games
                    </div>
                </Box>
                {
                    mobile ? undefined: navComponent.map(game =>
                        <Box
                            onClick={() => handleNavigation("/" + game.href)}
                            className={"tab " + (location.pathname.split("/")[1] === game.href ? "selected" : "")}
                            key={game.name}>
                            {game.name}
                        </Box>
                    )
                }
            </div>
            <div className="top-bar-right">
                {
                    isLoggedIn ?
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <div id="username-menu-button">
                                {user.username}
                            </div>
                            <IconButton variant="outlined" aria-label="delete" onClick={handleOpenMenu} style={{color: 'white', marginLeft: '8px', borderRadius: '10px', backgroundColor: '#1f2136'}}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    mobile ? navComponent.map(game => <MenuItem
                                            key={game.name}
                                            onClick={() => handleNavigation("/" + game.href)}>
                                            {game.name}
                                        </MenuItem>) :
                                        undefined
                                }
                                <MenuItem onClick={() => handleNavigation("/logout")}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div> :
                        <Button variant="contained" href={"/login"}>
                            Login
                        </Button>
                }
            </div>
        </div>
        <div style={{height: "70px"}}/>
    </div>;
};

